<template>
  <VList
    :rows="displayedRows"
    :headers="headers"
    :is-loading="isLoading"
    @click:row="onClickRedirect"
  >
    <!-- eslint-disable-next-line -->
    <template #item.image="{ item }">
      <VImage :name="item.displayed_name" :color="item.colour" />
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.name="{ item }">
      <div class="font-medium whitespace-nowrap">
        {{ item.displayed_name }}
      </div>

      <VLine class="font-light text-gray-600 text-xs whitespace-nowrap mt-0.5">
        <template #label>
          <div class="space">
            {{ $t("app.obtained_via") }}
          </div>
        </template>

        <template #value>
          <div class="font-medium">
            {{ item.displayed_source }}
          </div>
        </template>
      </VLine>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.status="{ item }">
      <div class="flex justify-center">
        <VChip
          :text="item.displayed_status"
          :class="getStatusColor(item.status)"
        />
      </div>
    </template>
  </VList>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
// Composables
import useColor from "@/composables/useColor";
import useDisplay from "@/composables/useDisplay";
import useTexts from "@/composables/useTexts";
// Components
import VList from "@/components/tables/VList";
import VImage from "@/components/VImage";
import VChip from "@/components/VChip";
import VLine from "@/components/VLine";

export default {
  components: {
    VList,
    VImage,
    VChip,
    VLine
  },
  props: {
    rows: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    // MISC
    const { t } = useI18n();
    const router = useRouter();

    // Constants
    const headers = [
      {
        value: "image",
        class: "w-10"
      },
      {
        text: t("app.competencies"),
        value: "name"
      },
      {
        text: t("app.obtained_on"),
        value: "displayed_obtained_on"
      },
      {
        text: t("app.expires_on"),
        value: "displayed_expires_on"
      },
      {
        text: t("app.status"),
        value: "status",
        class: "w-40"
      }
    ];

    // CUSTOM COMPOSABLES
    const { getStatusColor } = useColor();
    const { formatDate } = useDisplay();
    const { getText } = useTexts();

    // COMPUTED
    const displayedRows = computed(() => {
      return props.rows.map(row => ({
        ...row,
        rowClass: "cursor-pointer",
        displayed_name: getText(row?.competency?.texts, "name"),
        displayed_source: getText(row?.source?.texts, "name"),
        displayed_status: t(`app.${row?.status}`),
        displayed_expires_on: formatDate({ date: row?.expires_on }),
        displayed_obtained_on: formatDate({ date: row?.obtained_on })
      }));
    });

    // Methods
    const onClickRedirect = item => {
      router.push({
        name: `learning-competencies`,
        params: { id: item?.id }
      });
    };

    return {
      displayedRows,
      headers,
      onClickRedirect,
      // useColor
      getStatusColor
    };
  }
};
</script>

<template>
  <VList
    :rows="displayedRows"
    :headers="displayedHeaders"
    :is-loading="isLoading"
    @click:row="onClickRedirect"
  >
    <!-- eslint-disable-next-line -->
    <template #item.image="{ item }">
      <VImage :src="item.displayed_avatar" :name="item.displayed_name" />
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.name="{ item }">
      <div class="font-medium whitespace-nowrap">
        {{ item.displayed_name }}
      </div>

      <div class="text-gray-600 text-xs truncate max-w-prose mt-0.5">
        {{ item.displayed_details }}
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.competency_score="{ item }">
      <div class="flex justify-center">
        <VScore :score="item?.competency_score" />
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.completion_score="{ item }">
      <div class="flex justify-center">
        <VScore :score="item?.completion_score" />
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.status="{ item }">
      <div class="flex justify-center">
        <VChip
          :text="item.displayed_status"
          :class="getStatusColor(item.status)"
        />
      </div>
    </template>
  </VList>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
// Composables
import useColor from "@/composables/useColor";
import useDisplay from "@/composables/useDisplay";
// Components
import VList from "@/components/tables/VList";
import VImage from "@/components/VImage";
import VChip from "@/components/VChip";
import VScore from "@/components/VScore";

export default {
  components: {
    VList,
    VImage,
    VChip,
    VScore
  },
  props: {
    rows: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    // MISC
    const { t } = useI18n();
    const router = useRouter();

    // Constants
    const headers = [
      {
        value: "image",
        class: "w-10"
      },
      {
        text: t("app.learners"),
        value: "name"
      },
      {
        text: t("app.competency_score"),
        value: "competency_score",
        headerClass: "justify-center"
      },
      {
        text: t("app.completion_score"),
        value: "completion_score",
        headerClass: "justify-center"
      },
      {
        text: t("app.status"),
        value: "status",
        class: "w-40"
      }
    ];

    // CUSTOM COMPOSABLES
    const { getStatusColor } = useColor();
    const { getAvatarURL } = useDisplay();

    // COMPUTED
    const displayedRows = computed(() => {
      return props.rows.map(row => ({
        ...row,
        rowClass: "cursor-pointer",
        displayed_avatar: getAvatarURL(row?.avatar?.view_path),
        displayed_name: `${row.firstname} ${row.lastname}`,
        displayed_details: row?.roles?.map(role => role.name)?.join(", "),
        displayed_status: row.status ? t(`app.${row.status}`) : ""
      }));
    });
    const displayedHeaders = computed(() => {
      return headers.filter(header => header.value !== "__ACTIONS");
    });

    // Methods
    const onClickRedirect = item => {
      router.push({
        name: `teaching-learners-update`,
        params: { id: item?.id }
      });
    };

    return {
      displayedRows,
      displayedHeaders,
      onClickRedirect,
      // useRole
      headers,
      // useColor
      getStatusColor
    };
  }
};
</script>

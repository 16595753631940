<template>
  <div>
    <VTitle
      :title="
        $t('app.results_found_in', {
          module: $t(`app.${section}`),
          submodule: $t(`app.${subsection}`, 2),
          term
        })
      "
      class="route-title"
    />

    <component :is="component" :is-loading="isLoading" :rows="data" />
  </div>
</template>

<script>
import { onMounted, ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
// Composables
import useRequest from "@/composables/useRequest";
// Components
import VTitle from "@/components/VTitle";
import AdministrationUsers from "./types/AdministrationUsers";
import AdministrationRoles from "./types/AdministrationRoles";
import AdministrationDepartments from "./types/AdministrationDepartments";
import AdministrationSites from "./types/AdministrationSites";
import LearningAttestations from "./types/LearningAttestations";
import LearningCollectionAvailable from "./types/LearningCollectionAvailable";
import LearningCollectionRegistered from "./types/LearningCollectionRegistered";
import LearningCompetencies from "./types/LearningCompetencies";
import LearningEvaluations from "./types/LearningEvaluations";
import LearningLessons from "./types/LearningLessons";
import LearningTrainingsAvailable from "./types/LearningTrainingsAvailable";
import LearningTrainingsRegistered from "./types/LearningTrainingsRegistered";
import TeachingAttestations from "./types/TeachingAttestations";
import TeachingCollections from "./types/TeachingCollections";
import TeachingCompetencies from "./types/TeachingCompetencies";
import TeachingEvaluations from "./types/TeachingEvaluations";
import TeachingInstructors from "./types/TeachingInstructors";
import TeachingLearners from "./types/TeachingLearners";
import TeachingLessons from "./types/TeachingLessons";
import TeachingRoles from "./types/TeachingRoles";
import TeachingTrainings from "./types/TeachingTrainings";

export default {
  components: {
    VTitle,
    AdministrationUsers,
    AdministrationRoles,
    AdministrationDepartments,
    AdministrationSites,
    LearningAttestations,
    LearningCollectionAvailable,
    LearningCollectionRegistered,
    LearningCompetencies,
    LearningEvaluations,
    LearningLessons,
    LearningTrainingsAvailable,
    LearningTrainingsRegistered,
    TeachingAttestations,
    TeachingCollections,
    TeachingCompetencies,
    TeachingEvaluations,
    TeachingInstructors,
    TeachingLearners,
    TeachingLessons,
    TeachingRoles,
    TeachingTrainings
  },
  props: {
    type: {
      type: String,
      required: true
    },
    term: {
      type: String,
      required: true
    }
  },
  setup(props) {
    // Misc
    const { t } = useI18n();

    // Composables
    const { request } = useRequest();

    // Data
    const data = ref([]);
    const isLoading = ref(false);

    // Computed
    const component = computed(() => {
      // eslint-disable-next-line
      const capitalize = s => `${s.charAt(0).toUpperCase()}${s.slice(1).toLowerCase()}`;
      return props.type
        .split("_")
        .map(capitalize)
        .join("");
    });
    const documentTitle = computed(() => t("app.global_search_results"));
    const section = computed(() => props.type.split("_")[0].toLowerCase());
    const subsection = computed(() => props.type.split("_")[1].toLowerCase());

    // Methods
    const getData = async () => {
      isLoading.value = true;

      const response = await request({
        endpoint: "platform.search.results",
        pathParams: {
          type: props.type
        },
        queryParams: {
          term: props.term
        },
        showToaster: false
      });

      data.value = response?.payload?.data ?? [];

      isLoading.value = false;
    };

    // Lifecycle Hooks
    onMounted(async () => {
      await getData();
    });

    // Watch
    watch(
      () => props.type,
      async () => {
        await getData();
      }
    );

    watch(
      () => props.term,
      async () => {
        await getData();
      }
    );

    return {
      documentTitle,
      section,
      subsection,
      data,
      component,
      isLoading
    };
  }
};
</script>

<template>
  <!-- eslint-disable-next-line -->
  <div class="chip" :style="{ 'background-color': backgroundColor, 'color': color }">
    {{ score }}
  </div>
</template>

<script>
import { computed } from "vue";
// Composables
import useColor from "@/composables/useColor";

export default {
  props: {
    score: {
      type: [Number, String],
      required: true
    }
  },
  setup(props) {
    // Composables
    const { getContrastingColor } = useColor();

    // Computed
    const backgroundColor = computed(() => {
      if (props.score <= 20) {
        return "#FF0D0D";
      } else if (props.score <= 40) {
        return "#FF4E11";
      } else if (props.score <= 60) {
        return "#FF8E15";
      } else if (props.score <= 80) {
        return "#FAB733";
      } else if (props.score <= 90) {
        return "#ACB334";
      } else if (props.score <= 100) {
        return "#69B34C";
      }

      return "#FFFFFF";
    });

    const color = computed(() => {
      return getContrastingColor(backgroundColor.value);
    });

    return {
      backgroundColor,
      color
    };
  }
};
</script>

<style scoped lang="scss">
.chip {
  @apply flex justify-center items-center rounded px-4 py-0.5 font-medium cursor-default w-12 h-8;
}
</style>

<template>
  <VList
    :rows="displayedRows"
    :headers="displayedHeaders"
    :is-loading="isLoading"
    @click:row="onClickRedirect"
  >
    <!-- eslint-disable-next-line -->
    <template #item.image="{ item }">
      <VImage :color="item.colour" :name="item.name" />
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.name="{ item }">
      <div class="font-medium whitespace-nowrap">
        {{ item.name }}
      </div>

      <div class="text-gray-600 text-xs truncate max-w-prose mt-0.5">
        {{ item.displayed_details }}
      </div>
    </template>

    <!-- eslint-disable-next-line -->
    <template #item.status="{ item }">
      <div class="flex justify-center">
        <VChip
          :text="item.displayed_status"
          :class="getStatusColor(item.status)"
        />
      </div>
    </template>
  </VList>
</template>

<script>
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
// Composables
import useSite from "@/composables/useSite";
import useColor from "@/composables/useColor";
import useDisplay from "@/composables/useDisplay";
// Components
import VList from "@/components/tables/VList";
import VImage from "@/components/VImage";
import VChip from "@/components/VChip";

export default {
  components: {
    VList,
    VImage,
    VChip
  },
  props: {
    rows: {
      type: Array,
      default: () => []
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    // MISC
    const { t } = useI18n();
    const router = useRouter();

    // CUSTOM COMPOSABLES
    const { getStatusColor } = useColor();
    const { route, headers } = useSite();
    const { trimHTML } = useDisplay();

    // COMPUTED
    const displayedRows = computed(() => {
      return props.rows.map(row => ({
        ...row,
        rowClass: "cursor-pointer",
        displayed_details: trimHTML(row.address),
        displayed_status: row.status ? t(`app.${row.status}`) : ""
      }));
    });
    const displayedHeaders = computed(() => {
      return headers.filter(header => header.value !== "__ACTIONS");
    });

    // Methods
    const onClickRedirect = item => {
      router.push({
        name: `${route}-update`,
        params: { id: item?.id }
      });
    };

    return {
      displayedRows,
      displayedHeaders,
      onClickRedirect,
      // useSite
      headers,
      // useColor
      getStatusColor
    };
  }
};
</script>
